<template>
  <div>
        <kendo-grid
            ref="grid" 
            :data-source="dataSource"
            :filterable="filterableConfig"
            :sortable="true"
            :pageable="pageableConfig"
            :columns="columns"
            v-on:detailinit="detailInit"
            :resizable="true"
            >
        </kendo-grid>
  </div>
</template>


<script>
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import {globalfunc} from '../../../../shared/GlobalFunction';
import purchasingInvoiceServices from '../Script/PurcashingInvoiceServices';
import { apiBaseUrl } from '../../../../infrastructure/constant/connection';
import { kendo_grid } from '../../../../infrastructure/constant/variable';

export default {
    name: 'PurchasingInvoiceGrid',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
    },
    props: ['editClick', 'deleteClick', 'statusClick', 'pdfClick'],
    data: function () {
        return  {
            dataSource:  new kendo.data.DataSource ({
                page: 1,
                pageSize: kendo_grid.default_grid_pagesize,
                serverPaging: true,
                serverFiltering: true,
                serverSorting: true,
                transport: {
                    read: {
                        contentType: "application/json",
                        url: apiBaseUrl,
                        type: "POST",
                        data: function(e) {
                            var swapField = [
                                {field: "status", new_field: "\"purchasing_invoice\".\"status\""},
                                {field: "supplier_name", new_field: "\"PurchaseOrder__Supplier\".\"contact_name\""},
                                {field: "created_at", new_field: "\"Created\".\"created_at\""},
                            ];
                            const paging = {
                                Page : {Limit: e.pageSize,Page: e.page},
                                Filter : globalfunc.filterQueryBuilder(e.filter, swapField),
                                OrderBy : globalfunc.sortingQueryBuilder(e.sort, swapField)
                            }
                            const variables = {
                                paging : paging
                            }
                            return { 
                                query: purchasingInvoiceServices.readPurchasingInvoiceQuery(),
                                variables : variables
                            }
                        },
                        beforeSend: function (req) {
                            req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                            req.setRequestHeader('Path', window.location.pathname);
                        }
                    },
                    parameterMap: function(options, operation) {
                        return  kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    cache: false,
                },
                schema: {
                    data: function (response) {
                        if(response.data.GetPurchasingInvoiceV.purchasing_invoice == null){
                            return [];
                        }else{
                            return response.data.GetPurchasingInvoiceV.purchasing_invoice;
                        }
                    },
                    total: function (response) {
                        if(response.data.GetPurchasingInvoiceV.purchasing_invoice == null){
                            return 0;
                        }else{
                            return response.data.GetPurchasingInvoiceV.total;
                        }
                    },
                    model: {
                        fields: {
                            invoice_date: { type: "date" },
                            invoice_received_date: { type: "date" },
                            invoice_due_date: { type: "date" },
                            created_at: { type: "date" },
                        }
                    }
                },
                sort: { field: "created_at", dir: "desc" },
            }),
            columns:  [
                { title: "Action", width: 200, attributes: { style: "text-align:center;" }, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, template:this.columnButton},
                { field: "status", title: "Status", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:center;"} },  
                { field: "purchase_order_number", title: "Nomor PO", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } }, 
                { field: "supplier_name", title: "Supplier", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } }, 
                { field: "invoice_number", title: "Nomor Bukti", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } }, 
                { field: "supplier_invoice_number", title: "No. Invoice", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } }, 
                { field: "invoice_date", title: "Tgl. Invoice", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:center;"}, 
                    template: "#= kendo.toString(kendo.parseDate(invoice_date, 'yyyy-MM-dd'), 'yyyy-MM-dd') #" },
                { field: "invoice_received_date", title: "Tgl. Penerimaan Invoice", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:center;"}, 
                    template: "#= kendo.toString(kendo.parseDate(invoice_received_date, 'yyyy-MM-dd'), 'yyyy-MM-dd') #" },
                { field: "invoice_due_date", title: "Tgl. Jatuh Tempo", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:center;"}, 
                    template: "#= invoice_due_date == null ? '' : kendo.toString(kendo.parseDate(invoice_due_date, 'yyyy-MM-dd'), 'yyyy-MM-dd') #" },
                { field: "tax_invoice", title: "No. Faktur", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } }, 
                { field: "created_at", title: "Tanggal Dibuat", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:center;"}, 
                    template: "#= kendo.toString(kendo.parseDate(new Date(created_at).toISOString(), 'yyyy-MM-dd'), 'yyyy-MM-dd') #" },
                { field: "created_by", title: "Dibuat Oleh", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } }, 
            ],
            filterableConfig:  {
                extra: true,
                operators: kendo_grid.default_operator
            },
            pageableConfig: kendo_grid.default_pageable_config
        };
    },
    mounted: async function() {
        var grid = this.$refs.grid.kendoWidget();
        var deleteClick = this.$props.deleteClick;
        var editClick = this.$props.editClick;
        var statusClick = this.$props.statusClick;
       
        var GridElement = grid.element;

        GridElement.on("click", "#DeleteButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            deleteClick(dataItem.invoice_id);
        })

        GridElement.on("click", "#EditButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            editClick(dataItem.invoice_id, false);
        })

        GridElement.on("click", "#ViewButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            editClick(dataItem.invoice_id, true);
        })
        
        ////Status Form
        GridElement.on("click", "#StatusButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            const poData = {
                invoice_id : dataItem.invoice_id,
                invoice_number : dataItem.invoice_number,
                status : dataItem.status
            }
            
            statusClick(poData);
        })      
    },
    methods: {
        columnButton(){
            return this.$globalfunc.gridActionWithStatusChangesButton("Purchasing Invoice")
        },
        detailInit: async function (e) {
            var data = await purchasingInvoiceServices.getDetailInvoice(e.data.invoice_id);
            
            $('<div/>').appendTo(e.detailCell).kendoGrid({
                ref: "detailGrid",
                dataSource: {
                    transport: {
                        read: function (x) {
                            x.success(data.purchasing_invoice_detail);
                        },
                    },
                    parameterMap: function(options, operation) {
                        return kendo.stringify(
                            {
                                query: options.query,
                                variables: options.variables
                            }
                        );
                    },
                    schema: {
                        data: function (response) {
                            if(response == null){
                                return [];
                            }
                            else{
                                var result = response.filter(function (el) {
                                    return el.quantity > 0
                                });
                                return result;
                            }
                        },
                        total: function(response) {
                            if(response == null){
                                return 0;
                            }
                            else{
                                var result = response.filter(function (el) {
                                    return el.quantity > 0
                                });
                                return result.length;
                            }
                        }
                    },
                    pageSize: kendo_grid.default_detail_grid_pagesize,
                },
                scrollable: false,
                sortable: true,
                pageable: true,
                navigatable: true,
                resizable: true,
                columns: [
                    { field: 'product_code', title: "Kode Barang", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, editable: false, nullable: true },
                    { field: 'item_name', title: "Nama Barang", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" },  editable: false, nullable: true },
                    { field: 'quantity', title: "Jumlah Barang", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, editable: false, nullable: true, attributes: { "class": "k-text-right" } },
                    { field: 'uom', title: "Satuan", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, editable: false, nullable: true },
                    { field: 'discount_amount', title: "Diskon", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" },  editable: false, nullable: true, attributes: { "class": "k-text-right" } },
                    { field: 'vat_per_unit', title: "Harga Barang", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" },  editable: false, nullable: true, attributes: { "class": "k-text-right" }, format:"{0:N0}" },
                    { field: 'vat', title: "Vat", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" },  editable: false, nullable: true, attributes: { "class": "k-text-right" }, format:"{0:N0}" },
                ],
            });
        },
        changeStatus(status){
            this.dataSource =  new kendo.data.DataSource ({
                page: 1,
                pageSize: kendo_grid.default_grid_pagesize,
                serverPaging: true,
                serverFiltering: true,
                serverSorting: true,
                transport: {
                    read: {
                        contentType: "application/json",
                        url: apiBaseUrl,
                        type: "POST",
                        data: function(e) {
                            var swapField = [
                                {field: "status", new_field: "\"purchasing_invoice\".\"status\""},
                                {field: "supplier_name", new_field: "\"PurchaseOrder__Supplier\".\"contact_name\""},
                                {field: "created_at", new_field: "\"Created\".\"created_at\""},
                            ];
                            const paging = {
                                Page : {Limit: e.pageSize,Page: e.page},
                                Filter : globalfunc.filterQueryBuilder(e.filter, swapField),
                                OrderBy : globalfunc.sortingQueryBuilder(e.sort, swapField)
                            }
                            const variables = {
                                paging : paging,
                                status : status
                            }
                            return { query: purchasingInvoiceServices.readPurchasingInvoiceQuery(),
                                        variables : variables}
                        },
                        beforeSend: function (req) {
                            req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                            req.setRequestHeader('Path', window.location.pathname);
                        }
                    },
                    parameterMap: function(options, operation) {
                        return  kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    cache: false,
                },
                schema: {
                    data: function (response) {
                        if(response.data.GetPurchasingInvoiceV.purchasing_invoice == null){
                            return [];
                        }else{
                            return response.data.GetPurchasingInvoiceV.purchasing_invoice;
                        }
                    },
                    total: function (response) {
                        if(response.data.GetPurchasingInvoiceV.purchasing_invoice == null){
                            return 0;
                        }else{
                            return response.data.GetPurchasingInvoiceV.total;
                        }
                    },
                    model: {
                        fields: {
                            invoice_date: { type: "date" },
                            invoice_received_date: { type: "date" },
                            invoice_due_date: { type: "date" },
                            created_at: { type: "date" },
                        }
                    }
                },
                sort: { field: "created_at", dir: "desc" },
            })
        },
    }
}
</script>