import gql from 'graphql-tag';
import {globalfunc} from '../../../../shared/GlobalFunction.js';
import moment from 'moment';

class PurchasingInvoiceService {
    readPurchasingInvoiceQuery(){
        var query = `query($paging:ServerPaging, $status:String){
            GetPurchasingInvoiceV(Paging:$paging, Status:$status){
                purchasing_invoice{
                    created_at
                    status
                    invoice_number
                    supplier_invoice_number
                    invoice_id
                    purchase_order_id
                    purchase_order_number
                    invoice_date
                    invoice_received_date
                    invoice_due_date
                    tax_invoice
                    supplier_id
                    supplier_name
                    created_by
                }
                total
            }
        }`;
        return query;
    }

    async getDetailInvoice(id){
        const variables = {
            id : id
        }
        var query = gql`query($id:Int){
            GetPurchasingInvoiceV(InvoiceId:$id){ 
                purchasing_invoice{
                    created_at
                    last_update
                    invoice_id
                    invoice_number
                    status
                    purchase_order_id
                    purchase_order_number
                    invoice_date
                    invoice_due_date
                    invoice_received_date
                    supplier_id
                    supplier_name
                    ppn_tax_percentage
                    currency_id
                    currency_name
                    currency_code
                    exchange_rate
                    notes
                    supplier_invoice_number
                    tax_invoice
                    import_tax
                    custom_clearance
                    additional_tax
                    total
                    total_raw
                    created_by
                    purchasing_invoice_detail{
                        created_at
                        last_update
                        invoice_id
                        item_id
                        item_name
                        product_code
                        barcode
                        type_detail_id
                        uom
                        discount
                        discount_amount
                        quantity
                        price_per_unit
                        price
                        price_discount_per_unit
                        price_discount
                        vat_per_unit
                        vat
                        ordered_quantity
                        delivered_quantity
                        invoiced_quantity
                        remaining_quantity
                    }
                }
            }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query, variables);
        
        return result.data.GetPurchasingInvoiceV.purchasing_invoice[0];
    }
    
    readPurchasingPOQuery(){
        var query = `query {
            GetPurchasingInvoiceableItemListV2 {
                purchase_order_id
                purchase_order_number
                purchase_order_date
                request_number
                vendor_id
                vendor_name
                currency_id
                exchange_rate
                ppn_tax_percentage
                notes
            }
        }`;
        return query;
    }
    
    async getDetailPO(id){
        const variables = {
            id : id
        }
        var query = gql`query ($id:[Int]) {
            GetPurchasingInvoiceableItemListV2 (PurchaseOrderIds:$id) {
                purchase_order_id
                purchase_order_number
                purchase_order_date
                request_number
                vendor_id
                vendor_name
                currency_id
                exchange_rate
                ppn_tax_percentage
                notes
                invoiceable_detail {
                    purchase_order_id
                    item_id
                    product_code
                    barcode
                    type_detail_id
                    item_name
                    uom
                    discount
                    quantity
                    price_per_unit
                    price
                    vat
                    vat_per_unit
                    ordered_quantity
                    invoiced_quantity
                    remaining_quantity
                    delivered_quantity
                }
            }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query, variables);
        
        return result.data.GetPurchasingInvoiceableItemListV2[0];
    }

    // itemQtyChange(data){
    //     let arrayObj = [];
    //     if(data != null){
    //         for (let i = 0; i < data.length; i++) {
    //             var str = { 
    //                 item_id: data[i].item_id,
    //                 product_code: data[i].product_code,
    //                 item_name: data[i].item_name,
    //                 uom: data[i].uom,
    //                 ordered_quantity: data[i].ordered_quantity,
    //                 invoiced_quantity: data[i].invoiced_quantity,
    //                 delivered_quantity: data[i].delivered_quantity,
    //                 remaining_quantity: data[i].remaining_quantity,
    //                 quantity: data[i].quantity,
    //                 discount: data[i].discount,
    //                 price_per_unit: data[i].price_per_unit,
    //                 price: data[i].price,
    //                 vat_per_unit: data[i].vat_per_unit,
    //                 vat: data[i].vat_per_unit*data[i].quantity
    //             }
    //             arrayObj.push(str)
    //         }
    //     }
        
    //     return arrayObj;
    // }

    async getDropdownPPNTax(){
        var query = gql`query{
            GetMasterTax{
                percentage
            }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        var arrayData = [];
        if(result.data.GetMasterTax != null){
            for (let i = 0; i < result.data.GetMasterTax.length; i++) {
                var str = { value:result.data.GetMasterTax[i].percentage, 
                            label:result.data.GetMasterTax[i].percentage}
                arrayData.push(str);
            }
        }
        return arrayData;
    }

    async getVendorQuery(){
        var query = gql`query{
            GetContact(ContactType:"Vendor"){
                contact_id
                contact_name
            }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        var arrayData = [];
        if(result.data.GetContact != null){
            for (let i = 0; i < result.data.GetContact.length; i++) {
                var str = { value:result.data.GetContact[i].contact_id, 
                            label:result.data.GetContact[i].contact_name}
                arrayData.push(str);
            }
        }
        return arrayData;
    }  

    async getCurrency(){
        var query = gql`query{
            GetCurrency{
                id
                currency_code
            }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        var arrayData = [];
        if(result.data.GetCurrency != null){
            for (let i = 0; i < result.data.GetCurrency.length; i++) {
                var str = { value:result.data.GetCurrency[i].id, 
                            label:result.data.GetCurrency[i].currency_code}
                arrayData.push(str);
            }
        }
        return arrayData;
    }

    async addQuery(variables){
        var query = gql`mutation($data:NewPurchasingInvoice!){
            CreatePurchasingInvoice(New:$data)
        }`;
          
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }

    async editQuery(variables){
        var query = gql`mutation($id:Int!,$data:NewPurchasingInvoice!){
            UpdatePurchasingInvoice(ID:$id,New:$data)
        }`;
          
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }

    invoiceItemDataChange(data, formType){
        var arrayData = [];
        if(data.length != 0 ){
            for (let i = 0; i < data.length; i++) {
                var quantity = 0;
                var discountAmount = 0;
                var priceDiscountPerUnit = 0;
                var price = 0;

                if(formType == "Add"){
                    quantity = data[i].remaining_quantity;
                    discountAmount = data[i].price_per_unit * (data[i].discount/100);
                    priceDiscountPerUnit = data[i].price_per_unit - discountAmount;
                    price = data[i].price;
                }else{
                    quantity = data[i].quantity;
                    discountAmount = data[i].discount_amount;
                    priceDiscountPerUnit = data[i].price_discount_per_unit;
                    price = data[i].price_per_unit * quantity;
                }
                
                var str = { 
                    item_id: data[i].item_id,
                    product_code: data[i].product_code,
                    item_name: data[i].item_name,
                    ordered_quantity: data[i].ordered_quantity,
                    invoiced_quantity: data[i].invoiced_quantity,
                    delivered_quantity: data[i].delivered_quantity,
                    remaining_quantity: data[i].remaining_quantity,
                    uom: data[i].uom,
                    discount: data[i].discount,
                    discount_amount: discountAmount,
                    quantity: quantity,
                    price_per_unit: data[i].price_per_unit,
                    price: price,
                    price_discount_per_unit: priceDiscountPerUnit,
                    price_discount: priceDiscountPerUnit * quantity,
                    vat_per_unit: data[i].vat_per_unit,
                    vat: data[i].vat_per_unit * quantity,
                } 

                arrayData.push(str);
            }
        }

        return arrayData;
    }

}

export default new PurchasingInvoiceService();